import ScrollAppear from '../hooks/ScrollAppear';

function About() {
  return (
    <div className=" flex flex-col justify-center items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717] py-20 ">
      <ScrollAppear>
        <ul className="flex md:flex-row sm:flex-col flex-col md:gap-10 gap-10 md:mt-0 my-10 md:pt-20 mx-4">
          <li className=" flex sm:flex-col flex-row md:flex-col items-center bg-gradient-to-r from-[#111827] via-[#1f2937] to-[#111827] md:p-8 p-4 rounded-3xl md:h-[600px] h-[250px]">
            <img src="/images/Robin.png" alt="Robin (VD/Utvecklare)" className="rounded-full md:h-80 md:W-80 h-32"/>
            <div className="mt-6 bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text md:ml-0 ml-4">
              <h1 className="md:text-4xl text-3xl text-center italic">Robin Vikstedt</h1>
              <h2 className="md:text-3xl text-2xl text-center italic md:mt-6 mt-2">VD / Utvecklare</h2>

              <div className="flex flex-row justify-center md:mt-8 mt-4">
                <div>
                  <h3>Mail:</h3>
                  <h3>Mobil:</h3>
                </div>
                <div className="md:ml-6 ml-2">
                  <p>Robin@webtrapuf.se</p>
                  <p>+46 70-337 39 39</p>
                </div>              
              </div>

            </div>
          </li>
          <li className="flex flex-row md:flex-col items-center bg-gradient-to-r from-[#111827] via-[#1f2937] to-[#111827] md:p-8 p-4 rounded-3xl md:h-[600px] h-[250px]">
            <img src="/images/Danny.png" alt="Danny (Säljare)" className="rounded-full md:h-80 md:W-80 h-32"/>
            <div className="mt-6 bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text md:ml-0 ml-4">
              <h1 className="md:text-4xl text-3xl text-center italic">Danny Hong</h1>
              <h2 className="md:text-3xl text-2xl text-center italic md:mt-6 mt-2">Säljare</h2>
              
              <div className="flex flex-row justify-center md:mt-8 mt-4">
                <div>
                  <h3>Mail:</h3>
                </div>
                <div className="md:ml-6 ml-2">
                  <p>Danny@webtrapuf.se</p>
                </div>              
              </div>

            </div>
          </li>
        </ul>
      </ScrollAppear>
      <ScrollAppear>
        <div className=' md:w-[90vw] bg-gradient-to-r from-[#111827] via-[#1f2937] to-[#111827] '>
          <div className='md:w-2/3 w-4/5 mx-auto bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text py-10'>
            <h1 className='md:text-6xl text-3xl text-center italic'>Om oss</h1>
            <p className='md:text-3xl text-xl italic mt-8'>
              Vi är ett UF-företag som strävar efter att revolutionera företagsmarknadsföring genom att erbjuda innovativa digitala lösningar. Genom att kombinera vår kreativitet och tekniska expertis skapar vi möjligheter för företag och organisationer att nå ut på den digitala arenan på ett effektivt sätt.
            </p>
            <p className='md:text-3xl text-xl italic mt-8'>
              Vårt fokus ligger på att skapa skräddarsydda digitala marknadsföringsstrategier som passar varje kunds unika behov och mål. Oavsett om det handlar om att öka synligheten på sociala medier, optimera webbplatsens prestanda eller utveckla engagerande innehåll, så är vi här för att maximera er digitala närvaro.
            </p>
            <p className='md:text-3xl text-xl italic mt-8'>
              Vi förstår att varje företag och organisation är unik, vilket är anledningen till att vi anpassar våra tjänster för att säkerställa att ni når era specifika målgrupper. Genom att ständigt vara i framkant av digitala trender och teknologiska innovationer kan vi erbjuda er de senaste verktygen och strategierna för att hålla er konkurrenskraftiga på den digitala marknaden.
            </p>
            <p className='md:text-3xl text-xl italic mt-8'>
              Vårt engagemang för kvalitet och resultat gör oss till det självklara valet för företag och organisationer som vill skapa en stark och varaktig närvaro online. Kontakta oss idag för att diskutera hur vi kan hjälpa er att lyckas i den digitala världen!
            </p>
          </div>
        </div>
      </ScrollAppear>
    </div>
    
  );
}

export default About;