import { Link } from "react-router-dom";

const services = [
    {
      title: "Modern Design",
      description: "Utforska de senaste trenderna inom modern design och upptäck hur det kan höja din online-närvaro. Vi erbjuder innovativa och estetiskt tilltalande designlösningar som sätter ditt varumärke i framkant.",
      link: "/services/design",
      image: "/images/Blogg.jpg",
    },
    {
      title: "Professionell E-handel",
      description: "Ta ditt företag till nästa nivå med en professionell e-handelslösning skräddarsydd för dina behov. Vi levererar säkra och användarvänliga plattformar som ökar din närvaro och försäljning online.",
      link: "/services/eshop",
      image: "/images/E-shop.jpg",
    },
    {
      title: "Utvecklad Webbplats",
      description: "Förbättra din online-närvaro med en välutvecklad webbplats som speglar ditt varumärke och dina mål. Våra skräddarsydda webblösningar är optimerade för prestanda och användarvänlighet.",
      link: "/services/website",
      image: "/images/Info.jpg",
    },
  ];
  
export default function Services() {
  return (
    <div className="flex flex-col py-32 px-8 gap-8 items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717]">
      {services.map((service, index) => (
        <div
          key={index}
          className="flex flex-col md:flex-row w-full md:w-2/3 bg-gradient-to-r from-[#21262D] via-[#2F3941] to-[#21262D] rounded-lg p-8 shadow-md hover:shadow-lg transition-transform duration-300 transform hover:scale-105 mb-8"
        >
          <div className="md:w-1/2 pr-8">
            <h1 className="text-2xl md:text-4xl font-bold text-white mb-4">{service.title}</h1>
            <p className="text-gray-300">{service.description}</p>
            <Link to={service.link} className="w-3/6 md:w-1/6  text-blue-500 mt-4 hover:underline focus:outline-none focus:ring focus:border-blue-300 block">
              Läs mer...
            </Link>
          </div>
          <img
            src={service.image}
            alt={`${service.title} pic`}
            className="md:w-1/2 w-full md:h-[200px] sm:h-48 h-32 object-cover object-center mt-4 md:mt-0"
          />
        </div>
      ))}
    </div>
  );
}