import { Link } from "react-router-dom";

export default function Design() {
    return (
        <div className="flex flex-col items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717] p-4 md:p-8 py-20 md:py-32">
            <div className="w-full md:w-2/3 bg-gradient-to-r from-[#21262D] via-[#2F3941] to-[#21262D] rounded-lg p-4 md:p-8 shadow-md hover:shadow-lg mb-8">
                <img src="/images/Info.jpg" alt="E-shop" className="w-full h-64 md:h-80 object-cover rounded-md mb-6" />
                <Link to="/services" className="text-blue-500 hover:underline mb-2">Tillbaka</Link>
                <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">Webbdesign med fokus på användarens upplevelse</h1>
                <p className="text-gray-300 mb-4">Design är avgörande för att attrahera och behålla besökare på webben. Upp till 94% av det första intrycket på av en hemsida baseras på det visuella. Därför lägger vi alltid stor fokus på själva grundbulten vid bygget av webb, designen. </p>
                {/* <p className="text-gray-300 mb-4">För att hålla jämna steg med utvecklingen måste företag erbjuda smidiga, snabba och enkla köpupplevelser från intresse till beställning. För att möta dessa krav behöver du en e-handelslösning som är skräddarsydd baserat på vad du säljer och vem du säljer till.</p> */}
            </div>
        </div>
    );
}
