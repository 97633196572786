import { Link } from 'react-router-dom';

export default function PageNotFound() {
    return (
        <div className="h-screen flex items-center justify-center" style={{ backgroundImage: `url(/images/Background.png)` }}>
            <div className="flex flex-col text-center text-white">
                <h1 className="text-9xl">404</h1>
                <p className="text-2xl font-semibold">Page not found!</p>
                <Link to="/" className='bg-[#111827] m-4 rounded-3xl p-4'>
                    <p className='font-bold text-3xl text-gray-300'>Go to start page</p>
                </Link>
            </div>

        </div>
    );
}