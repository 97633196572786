import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
export default function NavbarNy() {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // className="fixed w-full top-0 z-40 p-4 flex justify-between"
    return (
        <div className={`fixed top-0 z-20 w-[100vw] transition-all duration-500 ${isScrolled ? 'bg-[#111827] shadow-xl' : 'bg-transparent shadow-none'}  ${isOpen ? 'bg-transparent' : ''}`} >
            <div className={` md:w-[90%] w-[100%]  p-4 flex justify-between mx-auto`} >

                <Link to="/">
                    <img className="lg:h-12 md:h-12 sm:h-12 h-10 " src="/images/logo-no-background.png" alt="WebTrap UF Logo" />
                </Link>
                <div className=" flex-row gap-4 hidden md:flex">
                    <div
                        className="relative"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <Link to="/services" className={`flex items-center gap-2 text-gray-300 duration-500 hover:bg-gray-700 hover:text-white px-3 py-2 mb-2 my-auto rounded-md text-lg font-bold ${isScrolled ? 'bg-gray-transparent' : 'bg-gray-500 text-white'}`}>
                            <div className="flex items-center gap-3">
                                <h1>Tjänster</h1>
                                <FaAngleDown className="h-[20px] w-[20px]" />
                            </div>
                        </Link>

                        {isHovered && (
                            <div className="absolute w-full text-center bg-gray-500 hover:bg-gray-700 text-white p-4  rounded-md">
                                {/* Add your clickable content */}
                                <Link to="/services/design" className="block mb-2 hover:bg-gray-500 px-2 rounded-lg">
                                    Design
                                </Link>
                                <Link to="/services/eshop" className="block mb-2 hover:bg-gray-500 px-2 rounded-lg">
                                    E-handel
                                </Link>
                                <Link to="/services/website" className="block mb-2 hover:bg-gray-500 px-2 rounded-lg">
                                    Website
                                </Link>
                                {/* Add more items as needed */}
                            </div>
                        )}
                    </div>
                    <Link to="/about" className={`text-gray-300 duration-500 hover:bg-gray-700 hover:text-white px-3 py-2 mb-2 my-auto rounded-md text-lg font-bold ${isScrolled ? 'bg-gray-transparent' : 'bg-gray-500 text-white'}`}>
                        <h1>
                            Om oss
                        </h1>
                    </Link>
                    <Link to="/projects" className={`text-gray-300 duration-500 hover:bg-gray-700 hover:text-white px-3 py-2 mb-2 my-auto rounded-md text-lg font-bold ${isScrolled ? 'bg-gray-transparent' : 'bg-gray-500 text-white'}`}>
                        <h1>
                            Cases
                        </h1>
                    </Link>
                    <Link to="/contact" className="text-gray-300 duration-500 bg-blue-600 hover:bg-blue-900 hover:text-white px-3 py-2 mb-2 my-auto rounded-md text-lg font-bold">
                        <h1>
                            Kontakta oss
                        </h1>
                    </Link>
                </div>





                {/* MOBIL VY */}
                <div className={`flex md:hidden  justify-end ${isOpen ? ' translate-x-0' : ''} ease-in-out duration-300 top-0 right-0`}>
                    {!isOpen ?
                        (
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className={`h-10 w-10 z-50 flex justify-center items-center transition-all duration-500 border border-black rounded-lg bg-gray-700 ${isScrolled ? 'text-gray-400' : 'text-gray-400'}`}
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>

                                <svg className="block h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        ) : (
                            <div></div>
                        )
                    }

                </div>


            </div>
            <div className={`top-0 right-0 z-20 fixed  w-full h-full ${isOpen ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-300`}>

                <div className="top-0 right-0 fixed bg-gradient-to-r from-[#111827] via-[#1f2937] to-[#111827] bg-opacity-100 sm:w-[30vw] w-[50vw] h-full ease-in-out duration-500 p-4">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className={` h-10 w-10 flex justify-center items-center ml-auto transition-all duration-500 ${isScrolled ? 'text-gray-400' : 'text-gray-400'}`}
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <svg className="block h-10 w-10 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div className="md:hidden pt-4" id="mobile-menu">


                        <div className="px-2 pt-2 pb-3  sm:px-3">
                            <Link to="/" onClick={() => setIsOpen(!isOpen)} className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium">
                                Hem
                            </Link>

                            <Link to="/services" onClick={() => setIsOpen(!isOpen)} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                                Tjänster
                            </Link>

                            <Link to="/projects" onClick={() => setIsOpen(!isOpen)} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                                Cases
                            </Link>

                            <Link to="/about" onClick={() => setIsOpen(!isOpen)} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                                Om oss
                            </Link>

                            <Link to="/contact" onClick={() => setIsOpen(!isOpen)} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                                Kontakta oss
                            </Link>


                        </div>
                        <div >
                            {/* <Langdrop/> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}