import ScrollAppear from '../hooks/ScrollAppear';

export default function Cases() {
    return (
        <div className=' flex flex-col justify-center items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717] md:py-20'>
            <div className="w-3/4 text-center pt-40 pb-20 bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text">
                <h1 className="md:text-5xl text-3xl font-bold leading-normal">Vad har vi tidigare gjort?</h1>
                <p className="md:text-2xl text-lg font-semibold mt-4">Dessa är några av dem företag som har varit kunder hos oss. <br /> {"(Vi har tillstånd att berätta om dessa kunder)"}</p>
            </div>
            <ul className="flex flex-wrap justify-center gap-8 p-4">
                <ScrollAppear>
                    <a href="https://gamerun.se/">
                        <li className="md:w-full flex md:flex-row flex-col justify-between bg-gradient-to-r from-[#111827] via-[#1f2937] to-[#111827] md:p-10 p-4 rounded-3xl">
                            <div className="flex items-center justify-center md:text-[2.5rem] text-[1.5rem] font-bold text-white py-4 ">
                                {/* <img src="images/gamerunUFLogo.png" alt="gamerun logo" className="mr-3 md:h-[40px] md:w-[40px] h-[30px] w-[30px]"/> */}
                                GameRun
                            </div>
                            <img src="/images/gamerunUFSite.png" alt="gamerun site img" className="w-[400px] h-[180px] rounded-xl md:mx-10 mx-auto"/>
                        </li>
                    </a>
                </ScrollAppear>
                <ScrollAppear>
                    <a href="https://ralsuf.se/">
                        <li className="md:w-full flex md:flex-row flex-col justify-between bg-gradient-to-r from-[#111827] via-[#1f2937] to-[#111827] md:p-10 p-4 rounded-3xl">
                            <div className="flex items-center justify-center md:text-[2.5rem] text-[1.5rem] font-bold text-white pb-4">
                                Rals UF
                            </div>
                            <img src="/images/RalsUFSite.png" alt="Rals site img" className="w-[400px] h-[180px] rounded-xl md:mx-10 mx-auto"/>
                        </li>
                    </a>
                </ScrollAppear>
            </ul>
        </div>
    );
}