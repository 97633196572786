import ContactForm from "../components/ContactForm"

export default function Contact() {
  return (
    <div className=" flex flex-col justify-center items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717] md:py-20 py-20">
      <ContactForm/>
    </div>
    
  );
}
