import { Outlet } from "react-router-dom"
import Navbar from "../components/LauoutComponents/Navbar";
import Footer from "../components/LauoutComponents/Footer";


export default function Layout() {
    return (
        <div className='select-none relative'>
            <Navbar/>
            <Outlet/>
            <Footer/>
        </div>
    );
}