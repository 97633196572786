import { Link } from "react-router-dom";

export default function Website() {
    return (
        <div className="flex flex-col items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717] p-4 md:p-8 py-20 md:py-32">
            <div className="w-full md:w-2/3 bg-gradient-to-r from-[#21262D] via-[#2F3941] to-[#21262D] rounded-lg p-4 md:p-8 shadow-md hover:shadow-lg mb-8">
                <img src="/images/Info.jpg" alt="website" className="w-full h-64 md:h-96 object-cover rounded-md mb-6"/>
                <Link to="/services" className="text-blue-500 hover:underline mb-2">Tillbaka</Link>
                <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">När du behöver ny hemsida till ditt företag</h1>
                <p className="text-gray-300 mb-4">Har du en idé som du vill förverkliga och behöver en ny hemsida? Då har du kommit rätt. Med vår långa erfarenhet av att driva webbprojekt hjälper vi dig hela vägen från idé till publicerad webbplats.</p>
                <p className="text-gray-300 mb-4">Ditt företags hemsida är det viktigaste verktyget för att etablera din digitala närvaro och kommunicera med din målgrupp. Till skillnad från sociala medier konkurrerar du inte på samma sätt om uppmärksamhet utan har chansen att bygga allt från tillit och relationer till leads och köp.</p>
            </div>
        </div>
    );
}
