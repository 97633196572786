import { Link } from "react-router-dom";

export default function Eshop() {
    return (
        <div className="flex flex-col items-center bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717] p-4 md:p-8 py-20 md:py-32">
            <div className="w-full md:w-2/3 bg-gradient-to-r from-[#21262D] via-[#2F3941] to-[#21262D] rounded-lg p-4 md:p-8 shadow-md hover:shadow-lg mb-8">
                <img src="/images/E-shop.jpg" alt="E-shop" className="w-full h-64 md:h-96 object-cover rounded-md mb-6" />
                <Link to="/services" className="text-blue-500 hover:underline mb-2">Tillbaka</Link>
                <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">Skräddarsydd e-handel för ditt företag</h1>
                <p className="text-gray-300 mb-4">Är du redo att börja sälja online? Vi hjälper dig att förverkliga din idé genom en skräddarsydd e-handel. Med WebTrap som din partner får du robusta och moderna lösningar med fokus på enastående kundupplevelser som genererar konverteringar.</p>
                <p className="text-gray-300 mb-4">För att hålla jämna steg med utvecklingen måste företag erbjuda smidiga, snabba och enkla köpupplevelser från intresse till beställning. För att möta dessa krav behöver du en e-handelslösning som är skräddarsydd baserat på vad du säljer och vem du säljer till.</p>
            </div>
        </div>
    );
}
