import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';


import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import Layout from './Pages/Layout';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Projects from './Pages/Cases';
import PageNotFound from './Pages/PageNotFound';

import Design from './Pages/Services/Design';
import Eshop from './Pages/Services/Eshop';
import Website from './Pages/Services/Website';
import Services from './Pages/Services/Services';




const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/projects',
        element: <Projects />
      },
      {
        path: '/services',
        element: <Services />,
      },
      {
        path: '/services/design',
        element: <Design />
      },
      {
        path: '/services/eshop',
        element: <Eshop />
      },
      {
        path: '/services/website',
        element: <Website />
      },

      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
