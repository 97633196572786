import ScrollAppear from '../hooks/ScrollAppear';

import { Link } from 'react-router-dom';

function HomeTest() {
    return (
        <div className='flex flex-col'>
            <div className='h-screen w-full flex md:flex-row items-center text-gray-300 bg-no-repeat md:bg-left bg-bottom bg-cover drop-shadow-2xl ' style={{ backgroundImage: `url(./images/StartImageHome.png)` }}>
                <ScrollAppear>
                    <div className='md:w-6/12 w-4/5 h-1/2 md:p-8 p-6 md:ml-40 md:mx-0 mx-auto bg-[#262626] bg-opacity-70 rounded-[20px] md:text-left text-center'>
                        <h1 className='md:text-3xl text-2xl font-bold md:leading-normal bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text py-4'>
                            Gör dina webb drömmar till verklighet hos oss!
                        </h1>
                        <p className='md:text-xl text-sm font-semibold md:mt-4 mt-2 md:mb-8 mb-4 md:w-3/4 w-full bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text'>
                            Vill du skapa din egen digitala e-butik eller en informativ webbsida? Vi arbetar med dig för att hjälpa er för ert önskade utseende.
                        </p>
                        <Link to='/contact' className='md:text-2xl text-xl font-bold leading-normal bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text border-2 hover:border-[#705c36] border-[#B2935Bff] md:px-6 px-4  py-1 rounded-3xl duration-500'>
                            Kontakta oss!
                        </Link>
                    </div>
                </ScrollAppear>
            </div>




            <div className='flex justify-center h-40 bg-gradient-to-r from-[#111827] via-[#111827] to-[#111827] shadow-2xl'>
                <ul className='md:w-[70%] w-full flex flex-row  items-center justify-between text-white md:text-4xl text-2xl p-8'>
                    <Link to="/services/eshop">
                        <ScrollAppear>
                            <li className='flex items-center gap-2 hover:underline md:border-b-0 border-b-2 hover:text-gray-300 transition-all'>
                                <h2 className=''>
                                    E-handel
                                </h2>
                            </li>
                        </ScrollAppear>
                    </Link>
                    <Link to="/services/design">
                        <ScrollAppear>
                            <li className='flex items-center gap-2 hover:underline md:border-b-0 border-b-2 hover:text-gray-300 transition-all'>
                                <h2 className=''>
                                    Design
                                </h2>
                            </li>
                        </ScrollAppear>
                    </Link>
                    <Link to="/services/website">
                        <ScrollAppear>
                            <li className='flex items-center gap-2 hover:underline md:border-b-0 border-b-2 hover:text-gray-300 transition-all'>
                                <h2 className=''>
                                    Hemsida
                                </h2>
                            </li>
                        </ScrollAppear>
                    </Link>
                </ul>
            </div>
            <div className="bg-gradient-to-r from-[#171717] via-[#262626] to-[#171717]">
      <ScrollAppear>
        <div className="md:py-20 py-10 md:w-[90vw] p-10 bg-opacity-70 text-center bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text mx-auto">
          <h2 className="md:text-5xl text-3xl font-bold leading-normal">Vad är ditt mål med webbplatsen?</h2>
          <p className="md:text-2xl text-lg font-semibold mt-4">
            Att förstå syftet med din webbplats är avgörande. Letar du efter att uppfylla en specifik behov eller funktion?
          </p>
        </div>
      </ScrollAppear>

      <ul className="md:w-[80vw] mx-auto text-2xl md:mt-8 mt-6 bg-gradient-to-r from-[#B2935Bff] to-[#E6D994ff] text-transparent bg-clip-text">
        {items.map((item, index) => (
          <ScrollAppear key={index}>
            <li className={`flex ${index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"} flex-col items-center ${index === items.length - 1 ? "pb-10" : "pb-4 md:pb-0 md:py-10"}`}>
              <img src={item.image} alt={item.alt} className="md:w-1/2 w-full md:h-[300px] sm:h-96 h-46 object-cover object-center" />
              <div className="md:w-1/2 md:p-8 p-4 text-center">
                <h3 className="text-4xl mb-4">{item.title}</h3>
                <p className="text-lg">{item.description}</p>
              </div>
            </li>
          </ScrollAppear>
        ))}
      </ul>
    </div>
        </div>

    );
}
const items = [
    {
      title: "E-handelsplattform",
      description: "Skapad för effektiv onlinesälj med robusta funktioner såsom produktlistningar, varukorgshantering och säkra betalningsalternativ.",
      image: "/images/E-shop.jpg",
      alt: "Webshop pic",
    },
    {
      title: "Design",
      description: "Vi förvandlar abstrakta idéer till konkreta visuella mästerverk. Genom noggrannhet och expertis skapar vår design varaktiga intryck och en djup förståelse för din unika vision.",
      image: "/images/Blogg.jpg",
      alt: "Blog pic",
    },
    {
      title: "Hemsida",
      description: "Vi specialiserar oss på skräddarsydda hemsidor som är konstnärligt gestaltade och precist anpassade efter dina behov. Upplev skillnaden med en webbdesign som är helt unik för dig.",
      image: "/images/Info.jpg",
      alt: "Info pic",
    },
  ];
export default HomeTest;